import React, { useState } from "react"
import createTemplate from "../../create-template"
import { useComponents } from "../../components"

function Maple({ styles, editing, ...props }) {
  const Elements = useComponents()
  const checkboxes_color = styles.forElement("heading").color
  const [flipped, setFlipped] = useState(false)

  let bg_color = {
    backgroundColor: styles.forElement("background").backgroundColor
  }
  let bg_img = styles.forElement("background")

  if (editing) {
    bg_color["position"] = "absolute"
    bg_color["height"] = "100%"
    bg_img["position"] = "absolute"
    bg_img["height"] = "100%"
  }

  const handleCardFlip = (value) => {
    setFlipped(value)
    window.scrollTo(0, 0)
  }

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-wrapper">
        <div className="formkit-bg-color" style={bg_color} />
        <div className="formkit-bg-image" style={bg_img} />
        <div className="formkit-cards">
          <div
            className={`formkit-card formkit-card-top ${
              flipped ? "formkit-hidden" : ""
            }`}
          >
            <Elements.Image
              className="formkit-form-image"
              name="image"
              defaults={{
                src:
                  "https://images.unsplash.com/photo-1437913135140-944c1ee62782?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjExODA0N30&utm_source=convertkit&utm_medium=referral&utm_campaign=api-credit?fit=max&w=800"
              }}
              size={{ w: 560, h: 330 }}
            />
            <div
              className="formkit-card-content"
              style={styles.forElement("form_background")}
            >
              <Elements.Heading
                className="formkit-heading"
                group="heading"
                name="heading"
                defaults={{
                  content: "All I need is your email"
                }}
              />
              <Elements.Region
                className="formkit-content"
                group="content"
                name="content"
              >
                <Elements.Content
                  defaults={{
                    content:
                      "<p>I know you're a busy Mom, so to save you some time and effort, I'll send my weekly articles straight to your inbox. My tips, experiences, and advice about motherhood are all yours, every week!</p>"
                  }}
                />
              </Elements.Region>
              <Elements.Button
                className="formkit-signup"
                type="button"
                name="button_link"
                group="button_link"
                onClick={() => handleCardFlip(true)}
                defaults={{ content: "Send me your Mama Musings, Jada!" }}
              />
              {editing && (
                <button
                  onClick={() => handleCardFlip(true)}
                  type="button"
                  name="edit_button"
                  style={{
                    cursor: "pointer",
                    display: "block",
                    margin: "20px auto",
                    fontSize: 18,
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    padding: "8px 12px",
                    outline: 0,
                    background: "#ffffff"
                  }}
                >
                  Click to edit step 2
                </button>
              )}
            </div>
          </div>
          <div
            className={`formkit-card formkit-card-bottom ${
              flipped ? "" : "formkit-hidden"
            }`}
          >
            <Elements.Image
              className="formkit-form-image formkit-second-image"
              name="image_two"
              defaults={{
                src:
                  "https://images.unsplash.com/photo-1437913135140-944c1ee62782?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjExODA0N30&utm_source=convertkit&utm_medium=referral&utm_campaign=api-credit?fit=max&w=800"
              }}
              size={{ w: 560, h: 330 }}
            />
            <div
              className="formkit-card-content"
              style={styles.forElement("form_background")}
            >
              {!editing ? (
                <Elements.Button
                  onClick={() => handleCardFlip(false)}
                  type="button"
                  className="formkit-exit"
                  name="exit"
                  defaults={{ content: "x" }}
                />
              ) : null}
              <Elements.Heading
                className="formkit-heading"
                group="heading"
                name="heading_two"
                tag="h2"
                defaults={{
                  content: "Just one more step!"
                }}
              />
              <Elements.Region
                className="formkit-content"
                group="content"
                name="content_two"
              >
                <Elements.Content
                  defaults={{
                    content:
                      "<p>Alright, Mama – just one more step and you're all set. Pop your email in below for instant access to my motherhood musings!</p>"
                  }}
                />
              </Elements.Region>
              <Elements.Form>
                <Elements.Errors />
                <Elements.CustomFields
                  style={{
                    color: checkboxes_color
                  }}
                >
                  <Elements.AddFieldButton />
                  <Elements.Button
                    name="submit"
                    group="button"
                    defaults={{ content: "Submit &amp; sign me up!" }}
                  />
                </Elements.CustomFields>
              </Elements.Form>
              <Elements.Content
                className="formkit-disclaimer"
                name="disclaimer"
                defaults={{
                  content:
                    "<p>We respect your privacy. Unsubscribe at any time.</p>"
                }}
              />
              {editing && (
                <button
                  onClick={() => handleCardFlip(false)}
                  type="button"
                  name="edit_button_two"
                  style={{
                    cursor: "pointer",
                    display: "block",
                    margin: "20px auto",
                    fontSize: 18,
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    padding: "8px 12px",
                    outline: 0,
                    background: "#ffffff"
                  }}
                >
                  Edit previous view
                </button>
              )}
            </div>
          </div>
        </div>
        <Elements.BuiltWith background="background" image="background" />
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Maple, { name: "Maple" })
